import * as React from "react";
import { graphql, HeadFC, Link, PageProps } from "gatsby";
import { Seo } from "components/Seo";
import { Layout } from "components/Layout";

const CategoryListPage: React.FC<PageProps<Queries.BookListQuery>> = ({
  data,
}) => {
  return (
    <Layout>
      <h1 className="text-3xl font-bold underline">紹介書籍一覧</h1>
      <ul className="flex flex-wrap gap-8 items-center">
        {data.allMicrocmsBook.nodes.map((node) => {
          return (
            <li key={node.bookId}>
              <Link to={`/book/${node.bookId}`}>
                <div className="flex flex-col items-center gap-2 p-2 shadow-sm">
                  {node.img?.url && (
                    // TODO: GatsbyImage
                    <img
                      src={node.img?.url}
                      alt=""
                      width={240}
                      height={240}
                      placeholder="blurred"
                    />
                  )}
                  <span>{node.title}</span>
                </div>
              </Link>
            </li>
          );
        })}
      </ul>
    </Layout>
  );
};

export const query = graphql`
  query BookList {
    allMicrocmsBook {
      nodes {
        bookId
        title
        img {
          url
        }
      }
    }
  }
`;

export default CategoryListPage;

export const Head: HeadFC = () => <Seo title="投稿一覧" />;
